@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
.grid-container {
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
}

.grid-container-new {
  background-color: #fff;
}
/* Grid header styles */

.grid-header-container {
  width: 140px;
  height: 70px;
  text-align: left;
  color: #fff;
  border: 2px solid #fff;
  z-index: 10;
  position: relative;
  border-radius: 7px;
}

.grid-header-container.grid-header-container-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.icon-description {
  border: 2px solid #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.grid-header-share {
  display: flex;
  align-items: center;
  position: absolute;
  transition: -webkit-transform 0.1s;
  transition: transform 0.1s;
  transition: transform 0.1s, -webkit-transform 0.1s;
}

.grid-header-share:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/* Main grid */

@-webkit-keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.grid-sticky-label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 8px;
}

.grid-sticky-user-ava {
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 100%;
  vertical-align: middle;
  margin: 0 20px;
  object-fit: cover;
}

.grid-group-toogle-container {
  margin-right: 10px;
}

.grid-group-toogle-container-close svg {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.grid-group-mark {
  position: absolute;
  width: 5px;
  background-color: #10ccc6;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

/* Global */

.grid-clickable-item {
  cursor: pointer;
}

.gumb-grid-cell-icon {
  vertical-align: middle;
}

.no-scroll {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.no-scroll::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

/*TODO: disable for mobile */
.ReactVirtualized__Grid {
  overflow-x: hidden !important;
}

.BottomLeftGrid_ScrollWrapper {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.BottomLeftGrid_ScrollWrapper::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

@media (max-width: 960px) {
  .ReactVirtualized__Grid {
    overflow-x: auto !important;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .ReactVirtualized__Grid::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.ReactVirtualized__Grid.no-x-scroll {
  overflow-y: auto !important;
}

.ReactVirtualized__Grid.scrollbar-hidden {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.ReactVirtualized__Grid.scrollbar-hidden::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.add-event-button {
  background-image: linear-gradient(270deg, #01c8a8 0%, #19e1e1 100%);
  box-shadow: 0 2px 8px 0 rgba(22, 223, 220, 0.18);
  height: 70px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-event-button-label-icon {
  display: flex;
  align-items: center;
}

.add-event-button-label {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 32px;
  color: #fff;
  margin-left: 13px;
}

.gumb-grid-cell-text {
  font-size: 14px;
}

.gumb-grid-cell-text-statistics {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.title-tooltip {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0000007a;
  border-radius: 0.25rem;
  z-index: 10000000;
  padding: 2px 6px;
  color: white;
  font-size: 12px;
}

.title-tooltip.info-tooltip {
  display: block;
  white-space: pre-wrap;
  overflow: hidden;
  line-break: normal;
}

.badges-container {
  display: flex;
}

.header-grid {
  width: 100%;
  overflow: scroll !important;
}

.footer-wrapper .ReactVirtualized__Grid {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.footer-wrapper .ReactVirtualized__Grid::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.grid-header-title {
  font-size: 12px;
}

.grid-header-title {
  font-size: 12px;
}

.grid-header-title {
  font-size: 12px;
}

.App {
  text-align: center;
}

#detect {
  height: 1px;
  width: 1px;
  position: absolute;
  left: -999em;
  top: -999em;
}

.react-toast-notifications__container {
  z-index: 1000000000 !important;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
}

.communities-list-scrollbar .ps__thumb-y,
.menu-list-scrollbar .ps__thumb-y {
  width: 11px;
}

.TopRightGrid_ScrollWrapper {
  .ReactVirtualized__Grid__innerScrollContainer {
    &>div:last-child {
      &>div {
        border-right: none !important;
      }
    }
  }
}

.skeleton {
  background-color: #e0e0e0;
  background-image: linear-gradient(90deg, #e0e0e0 25%, #f1f1f1 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  -webkit-animation: loading 1.5s infinite linear;
          animation: loading 1.5s infinite linear;
  border-radius: 4px;
}

@-webkit-keyframes loading {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

@keyframes loading {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

.skeleton-text {
  width: 100%;
  height: 16px;
}

.skeleton-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.skeleton-list {
  width: 100%;
  height: 58px;
}

.skeleton-box {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.grid-row:hover {
  background-color: #EBF2F7;
}

.divider-y+.divider-y {
  border-top: 1px solid #EBF2F7;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ebf2f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

