.App {
  text-align: center;
}

#detect {
  height: 1px;
  width: 1px;
  position: absolute;
  left: -999em;
  top: -999em;
}

.react-toast-notifications__container {
  z-index: 1000000000 !important;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.communities-list-scrollbar .ps__thumb-y,
.menu-list-scrollbar .ps__thumb-y {
  width: 11px;
}

.TopRightGrid_ScrollWrapper {
  .ReactVirtualized__Grid__innerScrollContainer {
    &>div:last-child {
      &>div {
        border-right: none !important;
      }
    }
  }
}

.skeleton {
  background-color: #e0e0e0;
  background-image: linear-gradient(90deg, #e0e0e0 25%, #f1f1f1 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite linear;
  border-radius: 4px;
}

@keyframes loading {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

.skeleton-text {
  width: 100%;
  height: 16px;
}

.skeleton-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.skeleton-list {
  width: 100%;
  height: 58px;
}

.skeleton-box {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.grid-row:hover {
  background-color: #EBF2F7;
}

.divider-y+.divider-y {
  border-top: 1px solid #EBF2F7;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}